import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import CodingSection from "./HelpComponents/CodingSection";
import OtherFeaturesSection from "./HelpComponents/OtherFeaturesSection";
import MotifSection from "./HelpComponents/MotifSection";
import TranscribedNonCoding from "./HelpComponents/TranscribedNonCoding";
import Sample from "./HelpComponents/Sample";
import Legend from "./HelpComponents/Legend";
import Tabs from "./HelpComponents/Tabs";
import { Divider } from "@material-ui/core";

// Hard coded Data URL
const codingImages = [
  {
    allFeaturesHeatmap:
      process.env.REACT_APP_APIURL + "/images/AllGenes/10352_AllGenes_Heatmap.png",
    allFeaturesColorbar:
      process.env.REACT_APP_APIURL + "/images/AllGenes/10352_AllGenes_Colorbar.png",
    boundFeaturesHeatmap:
      process.env.REACT_APP_APIURL + "/images/BoundGenes/10352_BoundGenes_Heatmap.png",
    boundFeaturesColorbar:
      process.env.REACT_APP_APIURL + "/images/BoundGenes/10352_BoundGenes_Colorbar.png",
    enrichedFeaturesHeatmap:
      process.env.REACT_APP_APIURL + "/images/EnrichedFeatures/10352_EnrichedFeatures_Heatmap.png",
    enrichedFeaturesColorbar:
      process.env.REACT_APP_APIURL + "/images/EnrichedFeatures/10352_EnrichedFeatures_Colorbar.png",
    promoterComposite:
      process.env.REACT_APP_APIURL + "/images/Promoter/10352_Promoter_Composite.png",
    promoterHeatmap:
      process.env.REACT_APP_APIURL + "/images/Promoter/10352_Promoter_Heatmap.png",
    promoterEnrichedHeatmap:
      process.env.REACT_APP_APIURL + "/images/Promoter/10352_EnrichedPromoter_Heatmap.png",
    promoterEnrichedComposite:
      process.env.REACT_APP_APIURL + "/images/Promoter/10352_EnrichedPromoter_Composite.png",
    tssComposite: process.env.REACT_APP_APIURL + "/images/TSS/10352_TSS_Composite.png",
    tssHeatmap: process.env.REACT_APP_APIURL + "/images/TSS/10352_TSS_Heatmap.png",
    tssEnrichedHeatmap:
      process.env.REACT_APP_APIURL + "/images/TSS/10352_EnrichedTSS_Heatmap.png",
    tssEnrichedComposite:
      process.env.REACT_APP_APIURL + "/images/TSS/10352_EnrichedTSS_Composite.png",
    tesComposite: process.env.REACT_APP_APIURL + "/images/TES/10352_TES_Composite.png",
    tesHeatmap: process.env.REACT_APP_APIURL + "/images/TES/10352_TES_Heatmap.png",
    tesEnrichedHeatmap:
      process.env.REACT_APP_APIURL + "/images/TES/10352_EnrichedTES_Heatmap.png",
    tesEnrichedComposite:
      process.env.REACT_APP_APIURL + "/images/TES/10352_EnrichedTES_Composite.png",
  },
];

const nonCodingImages = [
  {
    RNAPol3Heatmap:
      process.env.REACT_APP_APIURL + "/images/RNAPol3/10352_RNAPol3_Heatmap.png",
    arsHeatmap: process.env.REACT_APP_APIURL + "/images/ARS/10352_ARS_Heatmap.png",
    xelementHeatmap:
      process.env.REACT_APP_APIURL + "/images/Xelement/10352_X-ELEMENT_Heatmap.png",
    centromereHeatmap:
      process.env.REACT_APP_APIURL + "/images/Centromere/10352_CENTROMERE_Heatmap.png",
    ltrHeatmap: process.env.REACT_APP_APIURL + "/images/LTR/10352_LTR_Heatmap.png",
    RNAPol2Heatmap:
      process.env.REACT_APP_APIURL + "/images/RNAPol2/10352_RNAPol2_Heatmap.png",
    ltrColorbar: process.env.REACT_APP_APIURL + "/images/LTR/LTR_Colorbar.png",
    RNAPol3Colorbar:
      process.env.REACT_APP_APIURL + "/images/RNAPol3/RNAPol3_Colorbar.png",
    RNAPol2Colorbar:
      process.env.REACT_APP_APIURL + "/images/RNAPol2/10352_RNAPol2_Colorbar.png",
    cutHeatmap: process.env.REACT_APP_APIURL + "/images/CUT/10352_CUT_Heatmap.png",
    sutHeatmap: process.env.REACT_APP_APIURL + "/images/SUT/10352_SUT_Heatmap.png",
    xutHeatmap: process.env.REACT_APP_APIURL + "/images/XUT/10352_XUT_Heatmap.png",
    cutEnrichedHeatmap:
      process.env.REACT_APP_APIURL + "/images/CUT/10352_EnrichedCUT_Heatmap.png",
    sutEnrichedHeatmap:
      process.env.REACT_APP_APIURL + "/images/SUT/10352_EnrichedSUT_Heatmap.png",
    xutEnrichedHeatmap:
      process.env.REACT_APP_APIURL + "/images/XUT/10352_EnrichedXUT_Heatmap.png",
    trnaHeatmap: process.env.REACT_APP_APIURL + "/images/TRNA/10352_TRNA_Heatmap.png",
    srnaHeatmap: process.env.REACT_APP_APIURL + "/images/SRNA/10352_SRNA_Heatmap.png",
    srnaEnrichedHeatmap:
      process.env.REACT_APP_APIURL + "/images/SRNA/10352_EnrichedSRNA_Heatmap.png",
  },
];

const motifImages = [
  {
    motif1Logo:
      process.env.REACT_APP_APIURL + "/images/Meme/10352_memelogos/logo1.png",
    motif1LogoReverse:
      process.env.REACT_APP_APIURL + "/images/Meme/10352_memelogos/logo_rc1.png",
    motif1FourColor:
      process.env.REACT_APP_APIURL + "/images/Meme/10352_Motif_1_fourcolor.png",
    motif1Composite:
      process.env.REACT_APP_APIURL + "/images/Meme/10352_Motif_1_composite.png",
    motif1Heatmap:
      process.env.REACT_APP_APIURL + "/images/Meme/10352_Motif_1_Heatmap.png",
    motif2Logo:
      process.env.REACT_APP_APIURL + "/images/Meme/10352_memelogos/logo2.png",
    motif2LogoReverse:
      process.env.REACT_APP_APIURL + "/images/Meme/10352_memelogos/logo_rc2.png",
    motif2FourColor:
      process.env.REACT_APP_APIURL + "/images/Meme/10352_Motif_2_fourcolor.png",
    motif2Composite:
      process.env.REACT_APP_APIURL + "/images/Meme/10352_Motif_2_composite.png",
    motif2Heatmap:
      process.env.REACT_APP_APIURL + "/images/Meme/10352_Motif_2_Heatmap.png",
    motif3Logo:
      process.env.REACT_APP_APIURL + "/images/Meme/10352_memelogos/logo3.png",
    motif3LogoReverse:
      process.env.REACT_APP_APIURL + "/images/Meme/10352_memelogos/logo_rc3.png",
    motif3FourColor:
      process.env.REACT_APP_APIURL + "/images/Meme/10352_Motif_3_fourcolor.png",
    motif3Composite:
      process.env.REACT_APP_APIURL + "/images/Meme/10352_Motif_3_composite.png",
    motif3Heatmap:
      process.env.REACT_APP_APIURL + "/images/Meme/10352_Motif_3_Heatmap.png",
  },
];

const tutorialSteps = [
  {
    label: "Target Information",
    content: (
      <React.Fragment>
        <Typography component="span">
          For the purposes of this help section, a <strong>Cbf1</strong> dataset
          is used as the example.
          <br />
          <br />
          Each entry contains general information about the specific target
          including <strong>common name</strong>,
          <strong> systematic name</strong>, <strong>alias name(s)</strong>, and
          <strong> description </strong> from &nbsp;
          <a
            href="https://www.yeastgenome.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            yeastgenome.org
          </a>
          {/* https://mathiasbynens.github.io/rel-noopener/ */}
          .
          <br /> <br />
          Additional helpful links are provided for each sample: <br />
          <br />
        </Typography>

        <Typography component="span">
          <strong>
            <em>UCSC Genome Browser</em>
          </strong>
        </Typography>
        <br />
        <Typography component="span">
          clicking this link will load the current dataset into a genome
          browser, allowing the user to view sequencing reads at an individual
          locus in the yeast genome.
        </Typography>
        <br />
        <br />
        <Typography component="span">
          <strong>
            <em>SGD</em>
          </strong>
        </Typography>
        <br />
        <Typography component="span">
          clicking this link takes the user to the Saccharomyces Genome Database
          (yeastgenome.org) entry for this target.
        </Typography>
        <br />
        <br />
        <Typography component="span">
          <strong>
            <em>Meta Data</em>
          </strong>
        </Typography>
        <br />
        <Typography component="span">
          clicking this link brings up a new window that displays
          sample-specific information including the assay used to produce the
          data, the antibody used in chromatin immunoprecipitation, the
          environmental condition the sample was harvested under, sequencing
          statistics and a link to download sample specific data.
        </Typography>
        <br />
        <br />
        <Typography component="span">
          <strong>
            <em>Download</em>
          </strong>
        </Typography>
        <br />
        <Typography component="span">
          Within the Meta Data tab, you can click to download datasets for a
          single sample or add samples to the cart for a bulk download using
          <em> curl </em> from the terminal.
        </Typography>
        <br />
        <br />
        <Typography component="span">
          Assays used include ChIP-exo and MNase ChIP-seq. <br /> The majority
          of data was collected using yeast strains from a TAP-tag collection. A
          non-specific rabbit IgG was used to ChIP in these strains.
        </Typography>
      </React.Fragment>
    ),
    component: (
      <div>
        <Sample />
        <br />
        <br />
        <Tabs />
      </div>
    ),
  },
  {
    label: "TRANSCRIBED FEATURES - RNA POLYMERASE II - CODING",
    content: (
      <React.Fragment>
        <Typography component="span">
          Binding events were called for each sample based on two different
          criteria: <br /> <br />
          1.
          <strong>
            <em>CHEXMIX </em>
          </strong>
          is a peak caller designed to take advantage ofChIP-exo’s near base
          pair resolution. Binding events are called relative to a negative
          control. The negative control was produced by merging 12 ChIP-exo
          datasets produced from the parental BY4741 strain that lacks the
          TAP-tag.On the ChExMix tab, the combined 5’ end of the sequencing tags
          are shifted 8 base pairs downstream. This shift approximates the
          location of the cross-linking point.ChExMix was optimized to identify
          discreet binding events (i.e. Sequence-specific transcription
          factors). <br /> <br />
          2. <strong>Subsector analysis</strong> sets a lower threshold at lower
          resolution. Each sector (i.e. gene) was divided into five subsectors:
          <br />
          <br /> - Nucleosome Free Region <br /> - Transcription Start Site
          <br /> - 5’ end of the gene <br /> - 3’ end of the gene <br />
          - Transcription End Site. <br />
          <br /> Sequencing tags were summed in each subsector. Total tags were
          compared to the negative control, and a significant threshold
          requiring p &lt; 0.05 was used. On the Subsector tab, the combined 5’
          end of the sequencing tags are shifted 50 base pairs downstream. This
          shift approximates nucleosome array repeat that is present in the
          negative control (BY4741). This shift was informative when visualizing
          targets that have a broad and/or weak binding pattern. <br />
          <br />
          The user can toggle between these two analyses by clicking on the
          tabs. <br /> <br />
          The leftmost heatmap labelled <strong>ALL FEATURES</strong> displays
          the sequencing tags at all RNA polymerase II genes, centered on the
          gene midpoint. Genes were divided into 5 categories based on the
          architecture of the protein bound in the Nucleosome Free Region (see
          publication). Categories are designatedby the color bar (see legend).
          Within each category, genes were sorted by gene length. <br />
          <br />
          <Legend /> <br />
          The second heatmap labelled <strong>BOUND FEATURES </strong> is the
          list of all RNA polymerase II genes, centered on the gene
          midpointfiltered to only those features that are called bound in this
          dataset (based on ChExMix or Subsector).The tRNA-proximal genes were
          filtered out by default. The sort is the same as in the
          <strong>ALL FEATURES </strong>
          heatmap. <br /> <br />
          The three heatmaps on the right are same set of filtered features
          listed in the <strong>BOUND FEATURES</strong> , but these plots are
          centered at the <strong>Nucleosome Free Region</strong> midpoint
          (blue), the <strong>Transcription Start Site</strong> (green), and the
          <strong>Transcription End Site</strong> (orange). On the ChExMix tab,
          the NFR plot is sorted by NFR length (defined by the distance between
          the +1 and -1 nucleosome dyads); the TSS and TESS plots are sorted by
          the distance of the ChExMix peak to zero point, most downstream to
          most upstream peak.On the Subsector tab, the heatmaps are sorted by
          the sum of the total sequencing tabs in the displayed window.
          <br /> <br />
          Above the NFR, TSS, and TES heatmaps are corresponding composite
          plots. The gray line indicates the normalized signal in the negative
          control (BY4741). The maximum y-axis value for each plot was set to at
          least 5. The number in parentheses indicates the x-axis value where
          the max y-axis value occurred in the window. <br /> <br />
          The heatmap contrast was dynamically calculated for each sample using
          the Bound Features. A total-tag normalized tag pile up matrix is
          generated, across 2kb region upstream and downstream from the
          genemidpoints. A custom image compression algorithm similar to
          <a
            href="http://jtreeview.sourceforge.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Java TreeView
          </a>
          , uses this data matrix and a cut-off threshold of 95% to report the
          maximum contrast for the sample. All heatmaps for one sample are set
          to the same threshold unless otherwise noted.
        </Typography>
      </React.Fragment>
    ),
    component: <CodingSection images={codingImages[0]} />,
  },
  {
    label: "TRANSCRIBED FEATURES - NON CODING",
    content: (
      <React.Fragment>
        <Typography component="span">
          The four heatmaps are centered at the transcription start site of
          <strong> TRANSCRIBED FEATURES - NON CODING</strong>. The plots are
          filtered to only those features that are called bound in this dataset
          (based on ChExMix or Subsector). On the ChExMix tab, the plots are
          sorted by the distance of the ChExMix peak to zero point, most
          downstream to most upstream peak. On the Subsector tab, the heatmaps
          are sorted by the sum of the total sequencing tabs in the displayed
          window.
        </Typography>
      </React.Fragment>
    ),
    component: <TranscribedNonCoding images={nonCodingImages[0]} />,
  },
  {
    label: "NON TRANSCRIBED FEATURES",
    content: (
      <React.Fragment>
        <Typography component="span">
          The three heatmaps are centered at the start sites of OTHER FEATURES
          that are not transcribed by RNA polymerase II. These features are not
          filtered; all features are shown in all datasets. The features are
          sorted by the following criteria:
        </Typography>
        <br />
        <br />
        <Typography component="span">
          replication origins - origin of replication protein 6 (ORC6) tag count{" "}
          <br /> centromeres - Ctf19 complex (Mcm16) tag count <br />
          X-elements - feature length
        </Typography>
        <br />
        <br />

        <Typography component="span">
          The tRNAheatmap contrast for all datasets was set to 30. tRNA
          background signal is higher than RNA polymerase II genes. This
          contrast differentiates factors bound to tRNAs from background. See
          Tfc3 as an example of a factor that binds tRNA.
        </Typography>
      </React.Fragment>
    ),
    component: <OtherFeaturesSection images={nonCodingImages[0]} />,
  },
  {
    label: "Motif Analysis",
    content: (
      <React.Fragment>
        <Typography component="span">
          The MEME analysis of the ChExMix peaks is presented in this section. A
          maximum of the Top 3 motifs, sorted by significance, are presented.
          Users can toggle between the motifs by clicking on the tabs:
          <strong>MOTIF 1</strong>, <strong>MOTIF 2</strong>,
          <strong> MOTIF 3</strong>.
        </Typography>
        <br />
        <br />
        <Typography component="span">
          The motif logo is shown in the upper left. The user can toggle between
          the complementary DNA sequences of the motif by clicking on the
          <strong> Forward </strong>and <strong>Reverse</strong> buttons. Below
          the motif logo is a four color plot of the motif occurrences that
          overlap with ChExMix peaks. The nucleotide sequence is represented by
          the same colors in the motif logo:
          <span style={{ color: "#CC0000" }}>
            <strong>A</strong>
          </span>
          ,
          <span style={{ color: "#018000" }}>
            <strong>C</strong>
          </span>
          ,
          <span style={{ color: "#FFB301" }}>
            <strong>G</strong>
          </span>
          ,
          <span style={{ color: "#0700CC" }}>
            <strong>T</strong>
          </span>
          . The four color plot is centered on the motif midpoint oriented on
          the Forward strand. If the motif logo is an odd length, then the zero
          point is the central nucleotide. If the motif logo is an equal length
          (as presented here for Cbf1), then the zero point is the first
          nucleotide downstream of the center (e.g. the zero point of the Cbf1
          Motif 1 is the “G” in position 7 of 12). The motif occurrence sort is
          linked to the heatmap to the right.
        </Typography>
        <br /> <br />
        <Typography component="span">
          The heatmap on the right represents the strand-separated tags centered
          on the motif midpoint. Blue represents tags on the forward strand, and
          red represents tags on the reverse strand. Motif occurrences are
          sorted by the summation of tags on both strands. Above the heatmap is
          a composite plot. The positive y-axis represents tags on the forward
          strand. The negative y-axis represents tags on the reverse strand.
        </Typography>
        <Typography component="span">
          If a dataset produces no motifs, this section will be empty.
        </Typography>
      </React.Fragment>
    ),
    component: <MotifSection images={motifImages[0]} motifCount={"3"} />,
  },
];

// ///////////////////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    background: "#fafafa",
  },
  mainHeader: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(5),
  },
  content: {
    padding: 10,
    marginLeft: 10,
    marginRight: 10,
    maxWidth: "45vw",
  },
  leftSection: {
    maxWidth: "70vw",
    marginRight: 10,
    marginLeft: 20,
  },
  rightSection: {
    maxHeight: 780,
    overflow: "scroll",
  },
  stepper: {
    width: 300,
  },
  legend: {
    padding: 10,
  },
}));

export default function HelpStepper() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  function handleNext() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  return (
    <div className={classes.root}>
      <Paper square elevation={0} className={classes.mainHeader}>
        <Typography variant="h4" gutterBottom>
          Website Overview & Usage
        </Typography>
      </Paper>
      <br />
      <MobileStepper
        className={classes.stepper}
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            color="primary"
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button
            size="small"
            color="primary"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
      <br />
      <Grid container direction="row" wrap="nowrap">
        <Grid item className={classes.leftSection}>
          {tutorialSteps[activeStep].component}
        </Grid>
        <Grid item className={classes.rightSection}>
          <Paper square elevation={0} className={classes.header}>
            <Typography variant="subtitle1" component="p">
              <strong>{tutorialSteps[activeStep].label}</strong>
            </Typography>
          </Paper>
          <Paper square elevation={0}>
            <Divider />
            <Typography className={classes.content}>
              {tutorialSteps[activeStep].content}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
