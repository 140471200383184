import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Paper, Typography, Divider } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  mainHeader: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(5),
  },
  content: {
    padding: 10,
    margin: "0 auto",
    width: "60vw",
  },
  bottomSpace: {
    marginBottom: 10,
  },
});

class AboutUs extends React.Component {
  render() {
    const { classes } = this.props;
    // Setting the title of the browser tab
    document.title = "About | YEP";

    return (
      <div className={classes.root}>
        <Paper square elevation={0} className={classes.mainHeader}>
          <Typography variant="h4" gutterBottom>
            About Us
          </Typography>
        </Paper>
        <br />
        <Paper square elevation={1} className={classes.content}>
          <Typography variant="h5" gutterBottom className={classes.bottomSpace}>
            Yeast (Sacchromyces) Epigenome Project
          </Typography>
          <Divider />
          <br />
          <Typography
            component="div"
            variant="body1"
            gutterBottom
            className={classes.bottomSpace}
          >
            <strong> Authors:</strong>
            <p>
              Matthew J. Rossi, Prashant K. Kuntala, William K.M. Lai, Naomi
              Yamada, Nitika Badjatia, Chitvan Mittal, Guray Kuzu, Kylie
              Bocklund, Nina P. Farrell, Thomas R. Blanda, Joshua D. Mairose,
              Ann V. Basting, Katelyn S. Mistretta, David J. Rocco, Emily S.
              Perkinson, Gretta D. Kellogg, Shaun Mahony, B. Franklin Pugh*
            </p>
          </Typography>
          <Typography component="div" variant="body1" gutterBottom>
            <strong>Principal Investigator:</strong>
            <p>
              B. Franklin Pugh, Cornell University, New York, 14853. <br />
              email: fp265@cornell.edu
            </p>
          </Typography>
        </Paper>
        <br />
      </div>
    );
  }
}

AboutUs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(AboutUs));
